'use client'

import { Button } from '@mui/material'

// Types
import type { GeneralButtonAtomProps } from '@/types/components/atoms/buttons/general'

const GeneralButtonAtom = (props: GeneralButtonAtomProps) => {
	// Props
	const { title, onClick } = props

	return (
		<Button
			onClick={onClick}
			sx={{
				px: { xs: 1.5, md: 3.75 },
				py: { xs: 0.5, md: 1.875 },
				fontSize: { xs: 12, md: 14 },
				fontWeight: 700,
				lineHeight: '18px',
				bgcolor: 'primary.dark',
				color: 'common.white',
				textTransform: 'none'
			}}
		>
			{title}
		</Button>
	)
}

export default GeneralButtonAtom
